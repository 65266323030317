import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import * as Menu from '@radix-ui/react-dropdown-menu';
import { useContext } from 'react';

import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import Avatar from 'components/Navbar/ProfilePopover/Avatar';
import ProfileMenuLink from 'components/Navbar/ProfilePopover/ProfileMenuLink';
import useRole from 'components/User/useRole';
import useUser from 'components/User/useUser';
import { LogoutDoc } from 'generated/graphql';

const ProfilePopover = () => {
  const i18nContext = useContext(I18nContext);
  const { isSuperAdmin } = useRole();
  const { logout } = useAuth0();
  const user = useUser();
  const currentFleetId = useCurrentFleetId({ optional: true });
  const [setLogoutTime] = useMutation(LogoutDoc, {
    variables: { id: user.id },
  });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { logout_text },
    },
  } = i18nContext;

  return (
    <Menu.Root>
      <Menu.Trigger className="hover:bg-navbarHover px-2">
        <Tooltip text="Profile">
          <Avatar />
        </Tooltip>
      </Menu.Trigger>

      <Menu.Content sideOffset={7} align="end" className="min-w-20 bg-white rounded-4 p-1 shadow-card text-sm">
        <ProfileMenuLink
          to={`/${currentFleetId}/settings`}
          icon="personOutline"
          label={tSafe('components.ProfilePopover.profile-settings', { defaultValue: 'Profile Settings' })}
        />

        <ProfileMenuLink
          icon="logOutOutline"
          label={logout_text}
          onClick={() => {
            setLogoutTime().then(() => {
              logout({
                returnTo: window.location.origin,
                client_id:
                  process.env.NODE_ENV === 'development'
                    ? process.env.REACT_APP_AUTH0_CLIENT_ID
                    : 'DTjFirBJudxLvstt8pQl452C37rs6FzI',
              });
            });
          }}
        />
      </Menu.Content>
    </Menu.Root>
  );
};

export default ProfilePopover;
